import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const getBYLocation = () => {
  const baseUrl = getBaseEndpointUrl();
  console.log(baseUrl, "location");
  return Http.get(baseUrl + "/settings/location_list");
};
export const getByCountry = () => {
  const baseUrl = getBaseEndpointUrl();
  console.log(baseUrl, "getcountry");
  return Http.get(baseUrl + "/settings/country_list");
};

export const getByPlace = () => {
  const baseUrl = getBaseEndpointUrl();
  console.log(baseUrl, "getplace");
  return Http.get(baseUrl + "/settings/place_list");
};
