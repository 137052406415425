/** External Imports */
import { Col, Container, Row, Form, Input } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

/** Internal Imports */

import { linksRequest } from "../../store/bussiness/bussiness.action";
import { linkDetailValidator } from "../../validators/Validators";
import { bussnessCatSelector } from "../../store/bussiness/bussiness.model";
import { useTranslation } from "react-i18next";
function LinkDetail({ onNext }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {i18n, t} = useTranslation();
  const { id } = useParams();
  const arabicStatus = i18n.language == "ar";
  /* Selectores */
  const { getBussinessDetails } = useSelector(bussnessCatSelector);

  /** States */
  const [langSwitch, setLangSwitch] = useState(1);

  const initialValues = {
    company_link: getBussinessDetails?.link?.company_link
      ? getBussinessDetails?.link?.company_link
      : "",
    facebook_link: getBussinessDetails?.link?.facebook_link
      ? getBussinessDetails?.link?.facebook_link
      : "",
    twitter_link: getBussinessDetails?.link?.twitter_link
      ? getBussinessDetails?.link?.twitter_link
      : "",
    linked_in_link: getBussinessDetails?.link?.linked_in_link
      ? getBussinessDetails?.link?.linked_in_link
      : "",
    skype_link: getBussinessDetails?.link?.skype_link
      ? getBussinessDetails?.link?.skype_link
      : "",
  };
  const onSubmit = (formData) => {
    console.log(formData, "formdatass");
    formData.language_type = langSwitch;
    formData.arabicStatus = arabicStatus
    dispatch(linksRequest(formData));
  };

  const handleClickNext = () => {
    onNext();
  };
  /* UseEffect */

  /** Form Data */
  const formik = useFormik({
    initialValues,
    validationSchema: linkDetailValidator,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="add-listing-form details-listing-form form-space mb-60 wow fadeInUp">
          <Row>
            <Col md={6}>
              {" "}
              <h4 className="title">{t("social_media") + " " + t("information")}</h4>
            </Col>
            <Col md={6}>
              <div  className={`${arabicStatus ? "text-start" : "text-end"}`}>
                {langSwitch == 1 ? (
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => setLangSwitch(2)}
                    >
                    اﻟﻌﺮﺑﻴﺔ
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => setLangSwitch(1)}
                  >
                    English
                  </button>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="form_group">
                <label className="label-style">{t("website")} :</label>
                <input
                  type="company_link"
                  className="form_control"
                  placeholder={t("website")}
                  name="company_link"
                  value={values.company_link}
                  onChange={handleChange}
                />
                { errors.company_link?.en &&
                    <p className="text-danger">{arabicStatus ? errors.company_link.ar : errors.company_link.en}</p>
                  }
              </div>
            </Col>

            <Col lg={6}>
              <div class="form_group">
                <label className="label-style">{t("facebook")}:</label>
                <input
                  type="facebook_link"
                  className="form_control"
                  placeholder={t("facebook")+ " " + t("optional")}
                  name="facebook_link"
                  value={values.facebook_link}
                  onChange={handleChange}
                />
                { errors.facebook_link?.en &&
                    <p className="text-danger">{arabicStatus ? errors.facebook_link.ar : errors.facebook_link.en}</p>
                  }
              </div>
            </Col>
            <Col lg={6}>
              <div className="form_group">
                <label className="label-style">{t("twitter")}:</label>
                <input
                  type="twitter_link"
                  className="form_control"
                  placeholder={t("twitter")+ " " + t("optional")}
                  name="twitter_link"
                  value={values.twitter_link}
                  onChange={handleChange}
                />                  
                 { errors.twitter_link?.en &&
                    <p className="text-danger">{arabicStatus ? errors.twitter_link.ar : errors.twitter_link.en}</p>
                  }
              </div>
            </Col>
            <Col lg={6}>
              <div className="form_group">
                <label className="label-style">{t("linked_in")}:</label>
                <input
                  type="linked_in_link"
                  className="form_control"
                  placeholder={t("linked_in")+ " " + t("optional")}
                  name="linked_in_link"
                  value={values.linked_in_link}
                  onChange={handleChange}
                />
                 { errors.linked_in_link?.en &&
                    <p className="text-danger">{arabicStatus ? errors.linked_in_link.ar : errors.linked_in_link.en}</p>
                  }
              </div>
            </Col>
            <Col lg={6}>
              <div className="form_group">
                <label className="label-style">{t("skype")}:</label>
                <input
                  type="skype_link"
                  className="form_control"
                  placeholder={t("skype")+ " " + t("optional")}
                  name="skype_link"
                  value={values.skype_link}
                  onChange={handleChange}
                />
                  { errors.skype_link?.en &&
                    <p className="text-danger">{arabicStatus ? errors.skype_link.ar : errors.skype_link.en}</p>
                  }
              </div>
            </Col>
            <Col lg={12}>
              <div class="button">
                <button
                  class="main-btn"
                  type="submit"
                  onClick={handleClickNext}
                >
                  {t("submit")}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default LinkDetail;
