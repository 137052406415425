import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  FormGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAnglesRight,
  faHippo,
  faLocationDot,
  faMagnifyingGlass,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useTranslation } from "react-i18next";

/** Internal Imports */
import { businessRegisterValidator } from "../../validators/Validators";
/** Selectors */
import { getUserInfo } from "../../store/business_user/auth.selector";
import { getUserAllInfo } from "../../store/business_user/auth.selector";
import { settingSelector } from "../../store/Setting/setting.model";
import { bussnessCatSelector } from "../../store/bussiness/bussiness.model";
/** API Calls */
import {
  categoryListRequest,
  subcategoryListRequest,
} from "../../store/category/category.action";
import {
  settingCountryRequest,
  settingLocationRequest,
  settingPlaceRequest,
} from "../../store/Setting/setting.action";
import { categorySelector } from "../../store/category/category.model";
import { getBusinessRegisterLoading } from "../../store/business_user/business_user_details.selector";
import { TagsInput } from "react-tag-input-component";
import { registerBusinessRequest } from "../../store/business_user/business_user_details.action";
import { RegBussinesSelector } from "../../store/business_user/business_user_details.model";
import Swal from "sweetalert2";
import { linkGetRequest } from "../../store/bussiness/bussiness.action";

function RegisterBusiness1({ onSelectCategory, onNext }) {
  const { i18n, t } = useTranslation();
  let arabicStatus = i18n.language == "ar";
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const arbicLanguage = (i18n.language == "ar");
  const email = localStorage.getItem("email");

  /** Selectors */
  const { categories, subcaterory } = useSelector(categorySelector);
  const { getBussinessDetails } = useSelector(bussnessCatSelector);
  const { regSuccessLoading } = useSelector(RegBussinesSelector);
  const { location, country } = useSelector(settingSelector);

  /* FormData Init Value */
  const initialValues = {
    name:
      arbicLanguage
        ? getBussinessDetails?.general?.arabic_name
        : getBussinessDetails?.general?.name,
    mobile: getBussinessDetails?.general?.mobile
      ? getBussinessDetails?.general?.mobile
      : "",
    name_of_the_business:
      getBussinessDetails?.general?.name_of_the_business != null
        ? getBussinessDetails?.general?.name_of_the_business
        : "",
    door_no: getBussinessDetails?.general?.door_no
      ? getBussinessDetails?.general?.door_no
      : "",
    street: getBussinessDetails?.general?.street
      ? getBussinessDetails?.general?.street
      : "",
    area: getBussinessDetails?.general?.area
      ? getBussinessDetails?.general?.area
      : "",
    city: getBussinessDetails?.general?.city
      ? getBussinessDetails?.general?.city
      : "",
    state: getBussinessDetails?.general?.state
      ? getBussinessDetails?.general?.state
      : "",
    pin_code: getBussinessDetails?.general?.pin_code
      ? getBussinessDetails?.general?.pin_code
      : "",
    about_business: getBussinessDetails?.general?.about_business
      ? getBussinessDetails?.general?.about_business
      : "",
    keywords: getBussinessDetails?.general?.keywords
      ? getBussinessDetails?.general?.keywords?.split(",")
      : "",
  };

  /** States */
  const [isClicked, setIsClicked] = useState(false);
  const [isSubClicked, setIsSubClicked] = useState(false);
  const [categoryID, setCategoryID] = useState();
  const [subCategoryID, setsubCategoryID] = useState("");
  const [image, setImage] = useState();
  const [tags, setTags] = useState([]);
  const [cityId, setCityID] = useState();
  const [langSwitch, setLangSwitch] = useState(1);
  const [validated, setValidated] = useState(false);
  const [errorMsg, seterrorMsg] = useState({
    city: false,
    category: false,
    subCategory: false,
  });


  /* Function */
  const onSubmit = (formData) => {
    if (!cityId) {
      setValidated(false);
    } else {
      console.log(formData, 3454890567)
      formData.category_id = Number(categoryID);
      formData.sub_category_id = subCategoryID;
      formData.profile_imageurl = image;
      formData.keywords = tags.join(",");
      formData.email = email;
      formData.city = cityId;
      formData.language_type = langSwitch;
      console.log(formData, 3489564)
      formData.arabicStatus = arabicStatus
      dispatch(registerBusinessRequest({ formData }));
    }
  };

  const handleCategoryChange = (event) => {
    console.log(event.target.value, "erdd");

    setCategoryID(event.target.value);
    onSelectCategory(event.target.value);
  };
  const handleCityChange = (event) => {
    setCityID(event.target.value);
  };

  /** Form Data */
  const formik = useFormik({
    initialValues,
    validationSchema: businessRegisterValidator,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors, resetForm, setValues } =
    formik;

  console.log(touched, 456785)

  /* useEffect */
  useEffect(() => {
    setCategoryID(getBussinessDetails?.general?.category_id);
    setsubCategoryID(getBussinessDetails?.general?.sub_category_id);
    setTags(getBussinessDetails?.general?.keywords?.split(","));
    setCityID(getBussinessDetails?.general?.city);
  }, [getBussinessDetails.general]);

  useEffect(() => {
    dispatch(categoryListRequest({}));
    dispatch(settingLocationRequest({}));
    dispatch(subcategoryListRequest({}));
  }, []);

  useEffect(() => {
    dispatch(linkGetRequest(id));
  }, [id]);

  useEffect(() => {
    if (regSuccessLoading == true) {
      dispatch(linkGetRequest(id));
    }
  }, [regSuccessLoading]);
  useEffect(() => {
    if (langSwitch == 2) {
      console.log(getBussinessDetails?.general, 349584756)
      setValues({
        name: getBussinessDetails?.general?.arabic_name || "",
        name_of_the_business: getBussinessDetails?.general?.arabic_name_of_the_business || "",
        description: getBussinessDetails?.general?.arabic_description || "",
        address: getBussinessDetails?.general?.arabic_address || "",
        door_no: getBussinessDetails?.general?.arabic_door_no || "",
        street: getBussinessDetails?.general?.arabic_street || "",
        area: getBussinessDetails?.general?.arabic_area || "",
        city: getBussinessDetails?.general?.arabic_city || "",
        state: getBussinessDetails?.general?.arabic_state || "",
        about_business: getBussinessDetails?.general?.arabic_about_business || "",
        mobile: getBussinessDetails?.general?.mobile,
        pin_code: getBussinessDetails?.general?.pin_code,
      })
      setTags(getBussinessDetails?.general?.arabic_keywords?.split(","));
    } else {
      setValues({
        name: getBussinessDetails?.general?.name || "",
        name_of_the_business: getBussinessDetails?.general?.name_of_the_business || "",
        description: getBussinessDetails?.general?.description || "",
        address: getBussinessDetails?.general?.address || "",
        door_no: getBussinessDetails?.general?.door_no || "",
        street: getBussinessDetails?.general?.street || "",
        area: getBussinessDetails?.general?.area || "",
        city: getBussinessDetails?.general?.city || "",
        state: getBussinessDetails?.general?.state || "",
        about_business: getBussinessDetails?.general?.about_business || "",
        mobile: getBussinessDetails?.general?.mobile,
        pin_code: getBussinessDetails?.general?.pin_code,
      })
      setTags(getBussinessDetails?.general?.keywords?.split(","));
    }
    console.log(values, 4674)

  }, [langSwitch])
  useEffect(() => {
    console.log(values, 4674)
  }, [values])

  useEffect(() => {
    if (arabicStatus)
      setLangSwitch(2)
    else
      setLangSwitch(1)
  }, [arabicStatus])

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Row className="justify-content-center mt-5">
        <Col lg={12}>
          <div className="add-listing-form details-listing-form form-space wow fadeInUp">
            <Row>
              <Col md={6}>
                {" "}
                <h4 className="title">{t("register") + " " + t("information")}</h4>
              </Col>
              <Col md={6}>
                <div className={`${arabicStatus ? "text-start" : "text-end"}`}>
                  {langSwitch == 1 ? (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => setLangSwitch(2)}
                    >
                      اﻟﻌﺮﺑﻴﺔ
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => setLangSwitch(1)}
                    >
                      English
                    </button>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div class="form_group">
                  <label className="label-style">{t("user") + " " + t("name")}:</label>
                  <input
                    type="text"
                    className="form_control"
                    placeholder={t("your") + " " + t("user") + " " + t("name")}
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    dir={langSwitch == 1 ? "" : "rtl"}
                  />
                  { errors.name?.en &&
                    <p className="text-danger">{arabicStatus ? errors.name.ar : errors.name.en}</p>
                  }
                </div>
              </Col>
              <Col lg={6}>
                <div class="form_group">
                  <label className="label-style">{t("business") + " " + t("name")}:</label>
                  <input
                    type="text"
                    className="form_control"
                    placeholder={t("your") + " " + t("business") + " " + t("name")}
                    name="name_of_the_business"
                    value={values.name_of_the_business}
                    onChange={handleChange}
                    dir={langSwitch == 1 ? "" : "rtl"}
                  />
                  { errors.name_of_the_business?.en &&
                    <p className="text-danger">{arabicStatus ? errors.name_of_the_business.ar : errors.name_of_the_business.en}</p>
                  }
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div class="form_group">
                  <label className="label-style">{t("mobile")} :</label>
                  <input
                    type="number"
                    className="form_control"
                    placeholder={t("your") + " " + t("mobile")}
                    name="mobile"
                    value={Number(getBussinessDetails?.general?.mobile)}
                    onChange={handleChange}
                    dir={langSwitch == 1 ? "" : "rtl"}
                  />
                  { errors.mobile?.en &&
                    <p className="text-danger">{arabicStatus ? errors.mobile.ar : errors.mobile.en}</p>
                  }
                </div>
              </Col>
              <Col lg={6}>
                <div class="form_group">
                  <label className="label-style">{t("email")} :</label>
                  <input
                    type="email"
                    className="form_control"
                    placeholder={t("your") + " " + t("email")}
                    name="email"
                    value={getBussinessDetails?.general?.email}
                    readOnly
                    dir={langSwitch == 1 ? "" : "rtl"}
                  />
                { errors.email?.en &&
                    <p className="text-danger">{arabicStatus ? errors.email.ar : errors.email.en}</p>
                  }
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="form_group">
                  <label className="label-style">{t("category")}:</label>
                  <Form.Control
                    className="dn-space border-business"
                    as="select"
                    onChange={(e) => {
                      handleCategoryChange(e);
                    }}                    
                    defaultValue={categoryID}                                        
                    placeholder={t("category")}
                  >
                    {console.log(!isClicked,"SDFSF")}
                    <option value={0} key={0}>{t("select")}</option>
                    {categories?.data &&
                      categories?.data?.map((fields) => {
                        return (
                          <option value={fields.id} key={fields.id}>
                            {fields.name}
                          </option>
                        );
                      })}
                  </Form.Control>
                  {errorMsg?.category && (
                    <p className="text-danger">{t("category") + " " + t("is") + " " + t("required")}</p>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div class="form_group dn-space">
                  <label className="label-style">{t("sub_category")}:</label>
                  <Form.Control
                    className="border-business"
                    as="select"
                    onChange={(e) => {
                      setsubCategoryID(e.target.value);
                    }}
                    defaultValue={subCategoryID}
                    name="sub_category"
                    placeholder={t("subcategory")}
                  >
                    {!isSubClicked ? <option>{t("select")}</option> : ""}
                    {subcaterory?.data &&
                      subcaterory?.data
                        ?.filter((ele) => ele.category_id == categoryID)
                        .map((fields) => {
                          return (
                            <option value={fields.id}>{fields.name}</option>
                          );
                        })}
                  </Form.Control>                
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="form_group">
                  <label className="label-style">{t("door_no")}:</label>
                  <input
                    type="text"
                    className="form_control"
                    placeholder={t("your") + " " + t("door_no")}
                    name="door_no"
                    value={values.door_no}
                    onChange={handleChange}
                    dir={langSwitch == 1 ? "" : "rtl"}
                  />
                  { errors.door_no?.en &&
                    <p className="text-danger">{arabicStatus ? errors.door_no.ar : errors.door_no.en}</p>
                  }
                </div>
              </Col>
              <Col lg={6}>
                <div class="form_group">
                  <label className="label-style">{t("street")} :</label>
                  <input
                    type="text"
                    className="form_control"
                    placeholder={t("your") + " " + t("street")}
                    name="street"
                    value={values.street}
                    onChange={handleChange}
                    dir={langSwitch == 1 ? "" : "rtl"}
                  />
                  { errors.street?.en &&
                    <p className="text-danger">{arabicStatus ? errors.street.ar : errors.street.en}</p>
                  }
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="form_group">
                  <label className="label-style">{t("area")} :</label>
                  <input
                    type="text"
                    className="form_control"
                    placeholder={t("your") + " " + t("area")}
                    name="area"
                    value={values.area}
                    onChange={handleChange}
                    dir={langSwitch == 1 ? "" : "rtl"}
                  />
                  <p className="text-danger">{errors.area}</p>
                </div>
              </Col>
              {console.log(langSwitch, 45557)}
              <Col lg={6}>
                <div class="form_group">
                  <label className="label-style">{t("city")} :</label>
                  <Form.Control
                    className="dn-space border-business"
                    as="select"
                    onChange={(e) => {
                      handleCityChange(e);
                    }}
                    value={cityId}
                    name="city"
                    placeholder={t("your") + " " + t("city")}
                    dir={langSwitch == 1 ? "" : "rtl"}
                  >
                    {!isClicked ? <option>{langSwitch == 2 ? "اختر مدينة" : "Select"}</option> : ""}
                    {location &&
                      location?.map((fields) => {
                        return (
                          <option value={fields.id} key={fields.id}>
                            {langSwitch == 2 ? fields.arabic_name ?? fields.name : fields.name}
                          </option>
                        );
                      })}
                  </Form.Control>
                  {errorMsg?.city && (
                    <p className="text-danger">{t("city") + " " + t("is") + " " + t("required")}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="form_group">
                  <label className="label-style">{t("state")} :</label>
                  <input
                    type="text"
                    className="form_control"
                    placeholder={t("your") + " " + t("state")}
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                    dir={langSwitch == 1 ? "" : "rtl"}
                  />
                    { errors.state?.en &&
                    <p className="text-danger">{arabicStatus ? errors.state.ar : errors.state.en}</p>
                  }
                </div>
              </Col>
              <Col lg={6}>
                <div class="form_group">
                  <label className="label-style">{t("pin_code")} :</label>
                  <input
                    type="number"
                    className="form_control"
                    placeholder={t("your") + " " + t("pin_code")}
                    name="pin_code"
                    value={values.pin_code}
                    onChange={handleChange}
                    dir={langSwitch == 1 ? "" : "rtl"}
                  />
                  { errors.pin_code?.en &&
                    <p className="text-danger">{arabicStatus ? errors.pin_code.ar : errors.pin_code.en}</p>
                  }
                </div>
              </Col>
            </Row>
            <Row className="dn-space">
              <Col lg={6}>
                <div className="filled dn-space form-group tooltip-end-top">
                  <label className="label-style">{t("about") + "  " + t("business")}:</label>
                  <Form.Control
                    className="border-business"
                    as="textarea"
                    name="about_business"
                    placeholder="About Your Bussiness"
                    value={values.about_business}
                    onChange={handleChange}
                    dir={langSwitch == 1 ? "" : "rtl"}
                  />
                 { errors.about_business?.en &&
                    <p className="text-danger">{arabicStatus ? errors.about_business.ar : errors.about_business.en}</p>
                  }
                </div>
              </Col>
              <Col lg={6}>
                <div className="filled dn-space form-group tooltip-end-top">
                  <label className="label-style">{t("business") + " " + t("logo")} :</label>

                  <div className="filled form-group tooltip-end-top">
                    <Form.Control
                      className="border-business"
                      type="file"
                      accept="image/*"
                      name="profile_img"
                      onChange={(e) => setImage(e.target.files[0])}
                      placeholderText=""
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="filled form-group tooltip-end-top">
                  <label className="label-style">{t("keywords")} :</label>

                  <div className="filled form-group tooltip-end-top border-tag rtl_class">
                    <TagsInput
                      value={tags ?? []}
                      onChange={setTags}
                      name="keywords"
                      placeHolder={t("enter") + " " + t("keywords")}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <div class="button">
              <button class="main-btn continue" type="submit"
                onClick={() => {
                  if (!cityId) {
                    seterrorMsg({
                      city: !cityId,
                    });
                    setValidated(false);
                  }
                }}>
                {t("save") + " & " + t("continue")}
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default RegisterBusiness1;
