import { UserDetailTypes, userDetailInitialState } from "./userdetail.model";
const reducer = (state = userDetailInitialState, action) => {
  switch (action.type) {
    case UserDetailTypes.USERDETAIL_SEND_REQUEST:
      return {
        ...state,
        initLoading: true,
        sendSucces: false,
      };

    case UserDetailTypes.USERDETAIL_SEND_SUCCESS:
      return {
        ...state,
        initLoading: false,
        sendSucces: true,
      };
    case UserDetailTypes.USERDETAIL_SEND_FAILURE:
      return {
        ...state,
        initLoading: false,
        sendSucces: false,
      };
    case UserDetailTypes.GETUSERDETAIL_RECIEVE_REQUEST:
      return {
        ...state,
        getinitLoading: true,
      };
    case UserDetailTypes.GETUSERDETAIL_RECIEVE_SUCCESS:
      var { payload } = action;
      console.log(payload, "payloadesh");
      return {
        ...state,
        getinitLoading: false,
        getUserData: payload?.data.userDetails,
      };
    case UserDetailTypes.GETUSERDETAIL_RECIEVE_FAILURE:
      return {
        ...state,
        getinitLoading: false,
      };

    case UserDetailTypes.RESETSTATE_SEND_REQUEST:
      return {
        ...state,
        getinitLoading: true,
        getUserData: [],
      };

    default:
      return state;
  }
};
export { reducer as userDetailReducer };
