import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const postAllDetails=(postData)=>{
    console.log(postData,34578348)
    const baseUrl=getBaseEndpointUrl();
    return Http.post(baseUrl+ "/user/details_update", postData, Http.getAuthFileToken())
}
export const receiveAllDetails=(query)=>{
    const baseUrl=getBaseEndpointUrl();
    return Http.get(baseUrl+"/user/details_by_id/?query="+query, Http.getAuthToken())
}