/** External Imports */
import { Container, Row, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useEffect, useRef, useState, useTransition } from "react";
import Accordion from "react-bootstrap/Accordion";
import Slider from "react-slick";
import { faL, faPhone, faSquare } from "@fortawesome/free-solid-svg-icons";
import { faFontAwesome, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useParams, useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import Swal from "sweetalert2";
import QRCode from "react-qr-code";
import { Rating, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

//Internal Imports
import { bussnessCatSelector } from "../../store/bussiness/bussiness.model";
import noimage from "../../assets/images/listing/noimage.jpg";
import review1 from "../../assets/images/listing/review1.png";
import "../../assets/fonts/flaticon/flaticon.css";
import "../../assets/css/slick.css";
import "../../assets/css/nice-select.css";
import "../../assets/fonts/themify-icons/themify-icons.css";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { useSelector } from "react-redux";
import { API_BASE } from "../../services/config";
import { useDispatch } from "react-redux";
import app from "../../assets/images/app_icon.png";
import logo from "../../assets/images/listing/logo.png";

import {
  linkGetRequest,
  reviewListRequest,
  postreviewListRequest,
  postEnquiryListRequest,
  getMenuListRequest,
  getRelatedBussinessListRequest,
  postFavBussinessRequest,
  getAmentiesRequest,
  detailPageDataRequest,
} from "../../store/bussiness/bussiness.action";

import {
  EnquiryValidator,
  ReviewDetailValidator,
} from "../../validators/Validators";
import { settingSelector } from "../../store/Setting/setting.model";
import { settingLocationRequest } from "../../store/Setting/setting.action";

function Details() {
  const { id } = useParams();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const enquiryForm = useRef(null);
  let arabicStatus = i18n.language == "ar"
  const isAuth = localStorage.getItem("isAuthenticated");
  const userid = localStorage.getItem("id");
  console.log(id, "fsrture");

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  var restaurant = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const initialValues1 = {
    user_name: "",
    review_points: "",
    review_comment: "",
    user_mail: "",
    business_id: "",
  };

  const initialValues2 = {
    name: "",
    phone: "",
    enquiry: "",
  };

  /* Selectors */
  const {
    getBussinessDetails,
    reviewData,
    reviewCount,
    getMenuData,
    getRelatedBussiness,
    postSuccess,
    getAmentiesData,
    detailPageData,
  } = useSelector(bussnessCatSelector);
  const { location } = useSelector(settingSelector);

  console.log(detailPageData?.general, "vvvcc");
  /* States */
  const [img, setImg] = useState();
  const [durations, setDurations] = useState();
  const [error, setError] = useState(noimage);
  const [ratings, setRatings] = useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [userNumber, setUserNumber] = useState("");

  console.log(userNumber, "usernumber");
  console.log(ratings, "ratings");

  const [errorMsg, seterrorMsg] = useState({
    doj: false,
  });

  const [errorMsg1, seterrorMsg1] = useState({
    doj: false,
    tym: false,
  });
  const [startDate, setStartDate] = useState("");
  const [gettime, setGetTime] = useState("");
  const [getvalue, setGetValue] = useState();
  const [getgo, setGetGo] = useState();
  const [showReplyForm, setShowReplyForm] = useState(null);
  const [map, SetMap] = useState("");

  const [bussinessRev, setBussinessRev] = useState(0);
  console.log(typeof startDate, "kjfj");
  const reviewFormRef = useRef(null);

  /* Function */

  const ratingonSubmit = (formData) => {
    formData.review_points = ratings;
    formData.business_id = id;
    formData.arabicStatus = arabicStatus
    if (ratings != 0) dispatch(postreviewListRequest({ formData }));

    dispatch(linkGetRequest(id));
    const formData1 = {
      query: id,
      limit: 10,
      page: 1,
    };
    console.log(formData1, "SDFFGHF");
    dispatch(reviewListRequest(formData1));
  };

  const scrollToDiv = () => {
    if (enquiryForm.current) {
      enquiryForm.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const enquirySubmit = (formData) => {
    formData.business_id = parseInt(id);
    formData.date = startDate;
    formData.time = gettime;

    console.log(typeof formData, "allvalues");
    formData.arabicStatus = arabicStatus
    dispatch(postEnquiryListRequest({ formData }));
    resetForm();
    setGetTime("");
    setStartDate("");
  };
  const handleReplyClick = (index) => {
    setShowReplyForm(index);
  };

  const handleClose1 = () => {
    setShowReplyForm(null);
  };

  const navigateToReviewForm = () => {
    reviewFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const getCity = (cityId) => {
    return location?.find((cityData) => cityData.id == cityId)?.name;
  }
  /** Check the Business Favorite Status */
  const checkFav = (bussiness) => {
    console.log(bussiness, 43857);
    return bussiness?.user_favorite_business?.find(
      (favorites) => favorites?.business_id == bussiness?.id
    );
  };

  /** Review Value */
  const checkRev = (business) => {
    let average = 0;
    average = business?.business_user_reviews?.reduce((acc, review) => {
      return (
        acc +
        parseFloat(review?.review_points) /
        business?.business_user_reviews?.length
      );
    }, 0);
    console.log(business?.business_user_reviews, 345467786);

    average = average?.toFixed(2);
    average = parseFloat(average);
    return average;
  };
  /** Form Data */

  const formik1 = useFormik({
    initialValues: initialValues1,
    validationSchema: ReviewDetailValidator,
    onSubmit: ratingonSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik1;

  const formik2 = useFormik({
    initialValues: initialValues2,
    validationSchema: EnquiryValidator,
    onSubmit: enquirySubmit,
  });

  const {
    handleSubmit: handlesend,
    handleChange: handleEnquiry,
    values: newValues,
    touched: newtouched,
    errors: newErrors,
    resetForm,
  } = formik2;
  /* UseEffect */
  useEffect(() => {
    dispatch(settingLocationRequest({}));
  }, [])
  useEffect(() => {
    dispatch(detailPageDataRequest(id));

    const formData1 = {
      query: id,
      limit: 10,
      page: 1,
    };

    dispatch(reviewListRequest(formData1));
  }, [id]);

  useEffect(() => {
    setDurations(detailPageData?.timings);
  }, [detailPageData]);
  console.log(detailPageData, 6855546)

  useEffect(() => {
    console.log(detailPageData?.links?.company_link, 6855546)
    setImg(detailPageData?.media?.image_paths.split(","));
    // if (detailPageData?.link) SetMap(detailPageData?.links?.company_link);
  }, [detailPageData]);

  useEffect(() => {
    dispatch(getMenuListRequest(id));
    dispatch(getAmentiesRequest(id));
  }, [id]);

  useEffect(() => {
    let go = Object.groupBy(
      getMenuData?.rows ? getMenuData?.rows : "",
      ({ main_item }) => main_item
    );

    let data = Object.keys(go);
    setGetValue(data);
    setGetGo(go);
  }, [detailPageData, getMenuData]);

  useEffect(() => {
    let category = detailPageData?.general?.category_id;
    console.log(category, "csfdf");

    const value = {
      business_id: id,
      category_id: category,
    };
    dispatch(getRelatedBussinessListRequest(value));
  }, [detailPageData, id]);

  useEffect(() => {
    setUserNumber(detailPageData?.general?.mobile ?? "");
    let average = 0;
    average = detailPageData?.business_user_reviews?.reduce((acc, review) => {
      return (
        acc +
        parseFloat(review?.review_points) /
        detailPageData?.business_user_reviews?.length
      );
    }, 0);
    console.log(detailPageData?.business_user_reviews, 345467786);

    average = average?.toFixed(2);
    average = parseFloat(average);
    console.log(average, 3485786);
    setBussinessRev(average);
  }, [detailPageData]);

  useEffect(() => {
    if (postSuccess == true) {
      dispatch(linkGetRequest(id));
      const formData1 = {
        query: id,
      };

      dispatch(reviewListRequest(formData1));
    }
  }, [postSuccess]);

  return (
    <div>
      <Header></Header>

      <section className="banner">
        <div className="d-flex just-contents">
          <div className="banner-content d-flex">
            <div className="logo">
              <img src={logo} />
            </div>
            <div>
              <div className="listing-info-content">
                <h3 className="title">
                  {" "}
                  {i18n.language == "ar" ? detailPageData?.general?.arabic_name_of_the_business ?? detailPageData?.general?.name_of_the_business : detailPageData?.general?.name_of_the_business}
                </h3>
                <Rating className="ratings"
                  value={checkRev(detailPageData?.general)}
                  precision={0.5}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div>
            <button className="enquiry-btn" onClick={scrollToDiv}>{t("enquiry")}</button>
          </div>
        </div>
      </section>
      <section className="listing-details-section pb-90">
        <div className="infos d-flex">
          <div className="border ti-location-pin">
            <span>{t("get_directions")}</span>
          </div>
          <div className="border ti-mobile">
            <span>{t("call_now")}</span>
          </div>
          <div className="border ti-link">
            <Link to={detailPageData?.links?.company_link} target="_blank">{t("website")}</Link>
          </div>
          <div className="border ti-heart">
            <span
              onClick={() => {
                if (isAuth == "true") {
                  const sendId = {
                    business_id: parseInt(id),
                  };
                  sendId.arabicStatus = arabicStatus
                  dispatch(postFavBussinessRequest(sendId));
                } else {
                  Swal.fire({
                    title: t('login_to'),
                    text: t('login_error_msg'),
                    icon: "error",
                  });
                }
              }}
            >
              {t("bookmark")}
            </span>
          </div>

          <div className="border ti-comment">
            <span onClick={navigateToReviewForm}>{t("leave_review")}</span>
          </div>
        </div>
        <Container>
          <Row>
            <Col lg={8}>
              <div className="listing-details-wrapper listing-details-wrapper-two">
                <div className="listing-content mb-30 wow fadeInUp">
                  <h3 className="title">
                    {i18n.language == "ar"
                      ? detailPageData?.general?.arabic_name_of_the_business
                      : detailPageData?.general?.name_of_the_business}
                  </h3>
                  <div className="icon-box icon-box-one">
                    <div className="icon bg-success text-light">
                      {checkRev(detailPageData?.general)}
                    </div>
                    <Rating
                      value={checkRev(detailPageData?.general)}
                      precision={0.5}
                      readOnly
                    />
                    ( {detailPageData?.general?.business_user_reviews?.length + " " + t("reviews")} )
                  </div>
                  {/* About the Business */}
                  <h4 className="title">{t("about_business")}</h4>
                  <p>
                    {i18n.language == "ar"
                      ? detailPageData?.general?.arabic_about_the_business
                      : detailPageData?.general?.about_business}
                  </p>
                  {/* Amenities */}
                  <h4 className="title">{t("amenities")}</h4>
                  <Row>
                    {getAmentiesData.length > 0 ? (
                      getAmentiesData?.map((ele, ind) => {
                        return (
                          <Col lg={4} md={6} sm={12}>
                            <div className="icon-box icon-box-one">
                              <div className="icon">
                                <i className="ti-credit-card"></i>
                              </div>
                              <div className="info">
                                <h6>{ele.amenities_name}</h6>
                              </div>
                            </div>
                          </Col>
                        );
                      })
                    ) : (
                      <div className="mx-2">
                        {t("no") + " " + t("amenities") + " " + t("given")}
                      </div>
                    )}
                  </Row>
                </div>
                {getvalue?.length > 0 && (
                  <div className="listing-play-box mb-30 wow fadeInUp">
                    <div className="row">
                      <div className="col-md-6">
                        <h4 className="title">{t("menu")}</h4>
                      </div>
                      <div
                        style={{
                          height: "auto",
                          marginLeft: "16rem",
                          maxWidth: 64,
                          width: "100%",
                          padding: "0px",
                        }}
                        className="col-md-6 d-flex justify-content-end"
                      >
                        <QRCode
                          size={256}
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          value={map}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                    </div>
                    <Accordion className="menu-list" defaultActiveKey="0">
                      {getvalue?.map((ele, i) => {
                        console.log(ele, "jgqdqedi");
                        return (
                          <>
                            <Accordion.Item eventKey={i}>
                              <Accordion.Header>{ele}</Accordion.Header>
                              <Accordion.Body>
                                <Row className="">
                                  {getgo[ele]?.map((val) => {
                                    return (
                                      <Col lg={3} className="width">
                                        <div className="border">
                                          <Row className="menu-item">
                                            <Col lg={6}>
                                              <div className="category">
                                                <FontAwesomeIcon
                                                  className="veg"
                                                  icon={faSquare}
                                                />
                                              </div>
                                            </Col>
                                            <Col lg={6}>
                                              <p className="price">
                                                {val.price}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row className="menuname">
                                            <div>
                                              <p>{val.food_name}</p>
                                            </div>
                                          </Row>
                                        </div>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </Accordion.Body>
                            </Accordion.Item>{" "}
                          </>
                        );
                      })}
                    </Accordion>
                  </div>
                )}
                {/* {detailPageData?.general?.keywords && (
                  <div className="listing-tag-box mb-30 wow fadeInUp link">
                    <h4 className="title">{t("popular_tag")}</h4>
                    {detailPageData?.general?.keywords
                      ?.split(",")
                      ?.map((ele) => {
                        return (
                          <>
                            <span href={null}>{ele}</span>
                          </>
                        );
                      })}
                      
                  </div>
                )} */}
                {
                  (i18n.language == "ar") ?
                    detailPageData?.general?.arabic_keywords && (
                      <div className="listing-tag-box mb-30 wow fadeInUp link">
                        <h4 className="title">{t("popular_tag")}</h4>
                        {detailPageData?.general?.arabic_keywords
                          ?.split(",")
                          ?.map((ele) => {
                            return (
                              <>
                                <span href={null}>{ele}</span>
                              </>
                            );
                          })}

                      </div>
                    )
                    :
                    detailPageData?.general?.keywords && (
                      <div className="listing-tag-box mb-30 wow fadeInUp link">
                        <h4 className="title">{t("popular_tag")}</h4>
                        {detailPageData?.general?.keywords
                          ?.split(",")
                          ?.map((ele) => {
                            return (
                              <>
                                <span href={null}>{ele}</span>
                              </>
                            );
                          })}

                      </div>
                    )
                }
                <div className="listing-review-box mb-50 wow fadeInUp">
                  <h4 className="title">
                    {t("customer") + " " + t("review")}
                    <span className="title fs-6">
                      ({reviewCount} {t("reviews")})
                    </span>
                  </h4>

                  <ul className="review-list">
                    {reviewData?.map((reviews, index) => {
                      console.log(reviewData, 3453453453)
                      return (
                        <>
                          <li className="review" key={index}>
                            <div className="thumb">
                              <img
                                src={
                                  reviews?.business_user?.profile_imageurl !=
                                    null
                                    ? API_BASE +
                                    reviews?.business_user?.profile_imageurl
                                    : app
                                }
                              />
                            </div>
                            <div className="review-content w-100">
                              <h5>{reviews?.user_name}</h5>
                              <span className="date">
                                {dayjs(reviews?.created_at).format(
                                  "MMM DD YYYY"
                                )}
                              </span>
                              <p>{reviews?.review_comment}</p>
                              <div className="content-meta w-100 d-flex align-items-center justify-content-between">
                                <div>
                                  <Rating
                                    defaultValue={reviews?.review_points}
                                    precision={0.5}
                                    readOnly
                                  />
                                </div>
                                <span
                                  className="reply"
                                  onClick={() => handleReplyClick(index)}
                                >
                                  <i className="ti-share-alt"></i>{t("reply")}
                                </span>
                              </div>
                            </div>
                          </li>

                          {showReplyForm === index && (
                            <Form
                              className="mb"
                              show={showReplyForm === index}
                              onHide={handleClose1}
                            >
                              <Form.Group
                                className="mb-3"
                                controlId={`
                              -${index}`}
                              >
                                <Form.Label>{t("leave_reply")}</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type something..."
                                  autoFocus
                                />
                              </Form.Group>
                              <Button
                                className="btn-space"
                                variant="secondary"
                                onClick={handleClose1}
                              >
                                Close
                              </Button>
                              <Button variant="primary" onClick={handleClose1}>
                                Post
                              </Button>
                            </Form>
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <div
                    className="listing-review-form mb-30 wow fadeInUp"
                    ref={reviewFormRef}
                  >
                    <Row>
                      <Col lg={6}>
                        <h4 className="title">{t("write_review")}</h4>
                      </Col>
                      <Col md={6}>
                        <div className="form-rating">
                          <ul className="ratings">
                            <li>
                              <span>{t('rate') + " " + t('here')}:</span>
                            </li>
                            <Rating
                              name="half-rating"
                              precision={0.5}
                              onChange={(e) => {
                                const { value } = e.target;
                                setRatings(value);
                              }}
                              selected={ratings}
                            />
                          </ul>
                          {errorMsg?.doj && (
                            <p className="text-danger">{t('review') + " " + t('required')}</p>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={12}>
                        <div className="form_group">
                          <Form.Control
                            as="textarea"
                            className="form_control"
                            placeholder={t('writereview')}
                            name="review_comment"
                            value={values.review_comment}
                            onChange={handleChange}
                          />
                          <p className="text-danger">{i18n.language == "ar" ? errors.review_comment?.ar : errors?.review_comment?.en}</p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="form_group">
                          <input
                            type="text"
                            className="form_control"
                            placeholder={t('your_name')}
                            name="user_name"
                            value={values.user_name}
                            onChange={handleChange}
                          />
                          <p className="text-danger">{i18n.language == "ar" ? errors.user_name?.ar : errors?.user_name?.en}</p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="form_group">
                          <input
                            type="email"
                            className="form_control"
                            placeholder={t('email') + " " + t('here')}
                            name="user_mail"
                            value={values.user_mail}
                            onChange={handleChange}
                          />
                          <p className="text-danger">{i18n.language == "ar" ? errors.user_mail?.ar : errors?.user_mail?.en}</p>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="form_group">
                          <div className="single-checkbox d-flex">
                            <input
                              type="checkbox"
                              id="check4"
                              name="checkbox"
                            />
                            <label for="check4">
                              <span>
                                {t('remember_comments')}
                              </span>
                            </label>
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="form_group">
                          <button
                            className="main-btn icon-btn"
                            type="submit"
                            onClick={() => {
                              if (ratings == 0) {
                                seterrorMsg({
                                  doj: true,
                                });
                              } else {
                                seterrorMsg({
                                  doj: false,
                                });
                              }
                            }}
                          >
                            {t('submit') + " " + t('review')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>

              {getRelatedBussiness?.relatedCategory && (
                <div className="releted-listing-area wow fadeInUp">
                  <h3 className="title mb-20">{t('similar') + " " + t('business')}</h3>
                  <Slider {...restaurant}>
                    {getRelatedBussiness?.relatedCategory?.map((ele, i) => {
                      return (
                        <div className="listing-item listing-grid-item-two border">
                          <div className="listing-thumbnail ">
                            <img
                              src={
                                ele?.profile_imageurl != null
                                  ? API_BASE + ele?.profile_imageurl
                                  : app
                              }
                            />
                          </div>
                          <div className="listing-content">
                            <Row>
                              <Col md={6}>
                                <h3 className="title link">
                                  {i18n.language == "ar"
                                    ? ele.arabic_name_of_the_business
                                    : ele.name_of_the_business}
                                </h3>
                              </Col>
                              <Col md={6}>
                                <div className="link d-flex align-items-end flex-column">
                                  <i
                                    className={`ti-heart ${checkFav(ele)
                                      ? "wishlist-fill-icon"
                                      : "wishlist-icon"
                                      }`}
                                    onClick={(event) => {
                                      if (isAuth == "true") {
                                        const sendId = {
                                          business_id: parseInt(id),
                                        };
                                        sendId.arabicStatus = arabicStatus
                                        dispatch(
                                          postFavBussinessRequest(sendId)
                                        );
                                      } else {
                                        Swal.fire({
                                          title: t('login_to'),
                                          text: t('login_error_msg'),
                                          icon: "error",
                                        });
                                      }
                                      event.stopPropagation();
                                    }}
                                  ></i>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Rating
                                  value={checkRev(ele)}
                                  precision={0.5}
                                  readOnly
                                />{" "}
                              </Col>
                              <Col md={6}>
                                {ele?.business_user_reviews?.length +
                                  " " +
                                  t("reviews")}
                              </Col>
                            </Row>
                            <span className="phone-meta link">
                              <i className="ti-tablet"></i>
                              <a href={`tel:${ele?.mobile}`}>{ele?.mobile}</a>
                              <span
                                className="status st-open"
                                onClick={() => {
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                                  navigate(`/detail/${ele.id}`);
                                }}
                              >
                                {t("show")}
                              </span>
                            </span>
                            <div className="listing-meta">
                              <ul>
                                <li>
                                  {ele?.address && (
                                    <span>
                                      <i className="ti-location-pin"></i>
                                      {ele?.address}
                                    </span>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              )}
            </Col>
            <Col lg={4}>
              <div className="sidebar-widget-area">
                <div className="widget contact-info-widget mb-30 wow fadeInUp">
                  <div className="contact-info-widget-wrap">
                    <div className="contact-map">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d979.165998403989!2d76.9904892426032!3d10.988714646274927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859eba6c16a3f%3A0xede345463b586096!2sMount%20Carmel%20Nursery%20and%20Primary%20School!5e0!3m2!1sen!2sin!4v1720008179235!5m2!1sen!2sin"
                        style={{ border: "0", width: "600", height: "400" }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                    <div className="contact-info-content">
                      <h4 className="widget-title">{t("contact_info")}</h4>
                      <div className="info-list link">
                        <div className="call-btns">
                          <Button
                            className="blue-btn"
                            id="ScheduleUpdateTooltip"
                            onClick={() => {
                              if (isAuth != "true") {
                                Swal.fire({
                                  title: t('login_to'),
                                  text: t('login_error_msg'),
                                  icon: "error",
                                });
                              }
                            }}
                          >
                            {isAuth == "true" ? userNumber : t('show_number')}
                            <FontAwesomeIcon icon={faPhone} className="mx-1" />{" "}
                          </Button>

                          <Button className="chat mx-1">
                            <a
                              aria-label=""
                              href={`https://wa.me/${userNumber}`}
                            >

                              {t("chat")}{" "}
                            </a>{" "}
                            <FontAwesomeIcon
                              className="space"
                              icon={faWhatsapp}
                            />
                          </Button>

                          <i
                            className={`ti-heart mx-2 ${checkFav(detailPageData?.general)
                              ? "wishlist-fill-icon"
                              : "wishlist-icon"
                              }`}
                            onClick={(event) => {
                              if (isAuth == "true") {
                                const sendId = {
                                  business_id: parseInt(id),
                                };
                                sendId.arabicStatus = arabicStatus
                                dispatch(postFavBussinessRequest(sendId));
                              } else {
                                Swal.fire({
                                  title: t('login_to'),
                                  text: t('login_error_msg'),
                                  icon: "error",
                                });
                              }
                              event.stopPropagation();
                            }}
                          >
                            {" "}
                          </i>
                        </div>
                        {console.log(detailPageData?.general?.arabic_city ?? " ",
                          detailPageData?.general?.arabic_state ?? " ", 435645654)}
                        {detailPageData?.general?.city && (
                          <p>
                            <i className="ti-location-pin"></i>
                            {(i18n.language == "ar") ?
                              detailPageData?.general?.arabic_city ? detailPageData?.general?.arabic_city + " " +
                                detailPageData?.general?.arabic_state ?? " " : "No Address Provided"
                              :
                              getCity(detailPageData?.general?.city) + " " +
                              detailPageData?.general?.state
                            }
                          </p>
                        )}
                        <p>
                          <i className="ti-email"></i>
                          <a href={`mailto:${detailPageData?.general?.email}`}>
                            {detailPageData?.general?.email}
                          </a>
                        </p>
                        {detailPageData?.media?.company_link && (
                          <p>
                            <i className="ti-world"></i>
                            <a href="www.fioxen.com">
                              {detailPageData?.media?.company_link}
                            </a>
                          </p>
                        )}
                      </div>
                      <ul className="social-link link">
                        <li>
                          <a href="#">
                            <i className="ti-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="ti-twitter-alt"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="ti-pinterest"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="ti-dribbble"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="widget reservation-form-widget mb-30 wow fadeInUp" ref={enquiryForm}>
                  <h4 className="widget-title" id="Enquiry">
                    {t('send_enquiry')}
                  </h4>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handlesend();
                    }}
                  >
                    <div className={`form_group ${i18n.language == "ar" ? 'rtl' : 'ltr'}`} >
                      <input
                        type="text"
                        className={`form_control ${i18n.language == "ar" ? 'rtl-placeholder' : ''}`}
                        placeholder={t('name')}
                        name="name"
                        value={newValues.name}
                        onChange={handleEnquiry}
                      />
                      <i className="ti-user" ></i>
                      <p className="text-danger">{i18n.language == "ar" ? newErrors.name?.ar : newErrors?.name?.en}</p>
                    </div>
                    <div className={`form_group ${i18n.language == "ar" ? 'rtl' : 'ltr'}`}>
                      <input
                        type="text"
                        className={`form_control ${i18n.language == "ar" ? 'rtl-placeholder' : ''}`}
                        placeholder={t('phone')}
                        name="phone"
                        value={newValues.phone}
                        onChange={handleEnquiry}
                      />
                      <i className="ti-mobile" ></i>
                      <p className="text-danger">{i18n.language == "ar" ? newErrors.phone?.ar : newErrors?.phone?.en}</p>
                    </div>

                    <div className={`form_group ${i18n.language == "ar" ? 'rtl' : 'ltr'}`}>
                      <DatePicker
                        placeholderText={t('select_date')}
                        className={`form_control ${i18n.language == "ar" ? 'rtl-placeholder' : ''}`}
                        selected={startDate}
                        value={startDate}
                        onChange={(e) => setStartDate(e)}
                      />
                      <i className="ti-calendar" ></i>
                      {errorMsg1?.doj && (
                        <p className="text-danger">{t('select_date')}</p>
                      )}
                    </div>
                    <div >
                      <div className="form_group">
                        <input
                          type="time"
                          value={gettime}
                          onChange={(e) => setGetTime(e.target.value)}
                          className="form_control"
                        />
                      </div>
                      {errorMsg1?.tym && (
                        <p className="text-danger">{t('select_time')}</p>
                      )}
                    </div>
                    <div className={`form_group ${i18n.language == "ar" ? 'rtl' : 'ltr'}`}>
                      <Form.Control
                        as="textarea"
                        className={`form_control ${i18n.language == "ar" ? 'rtl-placeholder' : ''}`}
                        name="enquiry"
                        placeholder={t('about_your_bussiness')}
                        value={newValues.enquiry}
                        onChange={handleEnquiry}
                      />
                      <p className="text-danger">{i18n.language == "ar" ? newErrors.enquiry?.ar : newErrors?.enquiry?.en}</p>
                    </div>

                    <div className="form_group">
                      <button
                        className="main-btn icon-btn"
                        type="submit"
                        onClick={() => {
                          if (!startDate || !gettime) {
                            seterrorMsg1({
                              doj: !startDate,
                              tym: !gettime,
                            });
                          } else {
                            seterrorMsg1({
                              doj: false,
                              tym: false,
                            });
                          }
                        }}
                      >
                        {t('enquiry')}
                      </button>
                    </div>
                  </Form>
                </div>
                {
                  console.log(durations &&
                    JSON.parse(durations && durations["monday"])?.from, 54646464)
                }
                <div className="widget business-hour-widget mb-30 wow fadeInUp">
                  <h4 className="widget-title">{t('business') + " " + t('hour')}</h4>
                  <ul className="time-info">
                    <li>
                      <span className={`day
                            ${i18n.language == "ar" ?
                          durations &&
                            JSON.parse(durations && durations["monday"])?.from !=
                            "holiday"
                            ?
                            "rtl-timings" : "rtl-timings1" : ''}`}>{t('monday')}</span>
                      <span
                        className={
                          durations &&
                            JSON.parse(durations && durations["monday"])?.from !==
                            "holiday"
                            ? "time"
                            : "time st-close"
                        }
                      >
                        {" "}
                        {durations &&
                          JSON.parse(durations && durations["monday"])?.from ==
                          "holiday" ? (
                          <> {t('holiday')}</>
                        ) : (
                          <>
                            {durations &&
                              JSON.parse(durations && durations["monday"])
                                ?.from}
                            {" - "}
                            {durations &&
                              JSON.parse(durations && durations["monday"])?.to}
                          </>
                        )}
                      </span>
                    </li>
                    <li>
                      <span className={`day ${i18n.language == "ar" ? durations &&
                        JSON.parse(durations && durations["tuesday"])?.from !=
                        "holiday"
                        ? "rtl-timings" : "rtl-timings1" : ''}`}>{t('tuesday')}</span>
                      <span
                        className={
                          durations &&
                            JSON.parse(durations && durations["tuesday"])
                              ?.from !== "holiday"
                            ? "time"
                            : "time st-close"
                        }
                      >
                        {" "}
                        {durations &&
                          JSON.parse(durations && durations["tuesday"])?.from ==
                          "holiday" ? (
                          <> {t('holiday')}</>
                        ) : (
                          <>
                            {durations &&
                              JSON.parse(durations && durations["tuesday"])
                                ?.from}
                            {" - "}
                            {durations &&
                              JSON.parse(durations && durations["tuesday"])?.to}
                          </>
                        )}
                      </span>
                    </li>
                    <li>
                      <span className={`day ${i18n.language == "ar" ? durations &&
                        JSON.parse(durations && durations["wednesday"])?.from !==
                        "holiday"
                        ? "rtl-timings" : "rtl-timings1" : ''}`}>{t('wednesday')}</span>
                      <span
                        className={
                          durations &&
                            JSON.parse(durations && durations["wednesday"])
                              ?.from !== "holiday"
                            ? "time"
                            : "time st-close"
                        }
                      >
                        {" "}
                        {durations &&
                          JSON.parse(durations && durations["wednesday"])?.from ==
                          "holiday" ? (
                          <> {t('holiday')}</>
                        ) : (
                          <>
                            {durations &&
                              JSON.parse(durations && durations["wednesday"])
                                ?.from}
                            {" - "}
                            {durations &&
                              JSON.parse(durations && durations["wednesday"])
                                ?.to}
                          </>
                        )}
                      </span>
                    </li>
                    <li>
                      <span className={`day ${i18n.language == "ar" ? durations &&
                        JSON.parse(durations && durations["thursday"])?.from !==
                        "holiday"
                        ? "rtl-timings" : "rtl-timings1" : ''}`}>{t('thursday')}</span>
                      <span
                        className={
                          durations &&
                            JSON.parse(durations && durations["thursday"])
                              ?.from !== "holiday"
                            ? "time"
                            : "time st-close"
                        }
                      >
                        {" "}
                        {durations &&
                          JSON.parse(durations && durations["thursday"])?.from ==
                          "holiday" ? (
                          <> Holiday</>
                        ) : (
                          <>
                            {durations &&
                              JSON.parse(durations && durations["thursday"])
                                ?.from}
                            {" - "}
                            {durations &&
                              JSON.parse(durations && durations["thursday"])
                                ?.to}
                          </>
                        )}
                      </span>
                    </li>
                    <li>
                      <span className={`day ${i18n.language == "ar" ? durations &&
                        JSON.parse(durations && durations["friday"])?.from !==
                        "holiday"
                        ? "rtl-timings" : "rtl-timings1" : ''}`}>{t('friday')}</span>
                      <span
                        className={
                          durations &&
                            JSON.parse(durations && durations["friday"])?.from !==
                            "holiday"
                            ? "time"
                            : "time st-close"
                        }
                      >
                        {" "}
                        {durations &&
                          JSON.parse(durations && durations["friday"])?.from ==
                          "holiday" ? (
                          <> {t('holiday')}</>
                        ) : (
                          <>
                            {durations &&
                              JSON.parse(durations && durations["friday"])
                                ?.from}
                            {" - "}
                            {durations &&
                              JSON.parse(durations && durations["friday"])?.to}
                          </>
                        )}
                      </span>
                    </li>
                    <li>
                      <span className={`day ${i18n.language == "ar" ? durations &&
                        JSON.parse(durations && durations["saturday"])?.from !==
                        "holiday"
                        ? "rtl-timings" : "rtl-timings1" : ''}`}> {t('saturday')}</span>
                      <span
                        className={
                          durations &&
                            JSON.parse(durations && durations["saturday"])
                              ?.from !== "holiday"
                            ? "time"
                            : "time st-close"
                        }
                      >
                        {" "}
                        {durations &&
                          JSON.parse(durations && durations["saturday"])?.from ==
                          "holiday" ? (
                          <> {t('holiday')}</>
                        ) : (
                          <>
                            {durations &&
                              JSON.parse(durations && durations["saturday"])
                                ?.from}
                            {" - "}
                            {durations &&
                              JSON.parse(durations && durations["saturday"])
                                ?.to}
                          </>
                        )}
                      </span>
                    </li>
                    <li>
                      <span className={`day ${i18n.language == "ar" ? durations &&
                        JSON.parse(durations && durations["sunday"])?.from !==
                        "holiday"
                        ? "rtl-timings" : "rtl-timings1" : ''}`}>{t('sunday')}</span>
                      <span
                        className={
                          durations &&
                            JSON.parse(durations && durations["sunday"])?.from !==
                            "holiday"
                            ? "time"
                            : "time st-close"
                        }
                      >
                        {" "}

                        {durations &&
                          JSON.parse(durations && durations["sunday"])?.from ==
                          "holiday" ? (
                          <> {t('holiday')}</>
                        ) : (
                          <>
                            {durations &&
                              JSON.parse(durations && durations["sunday"])
                                ?.from}
                            {" - "}
                            {durations &&
                              JSON.parse(durations && durations["sunday"])?.to}
                          </>
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default Details;
