/** Package Imports */
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/** Internal Imports */
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/niceselect.css";
import "../assets/css/default.css";
import "../assets/fonts/themify-icons/themify-icons.css";

/** Internal Components */
import ListView from "./ListView";
import GridView from "./GridView";
import Header from "./layout/Header";
import Footer from "./layout/Footer";

/** API Calls */
import { categoryListRequest } from "../store/category/category.action";
import { categorySelector } from "../store/category/category.model";
import {
  settingCountryRequest,
  settingLocationRequest,
  settingPlaceRequest,
} from "../store/Setting/setting.action";
import {
  searchRequest,
} from "../store/bussiness/bussiness.action";
import { bussnessCatSelector } from "../store/bussiness/bussiness.model";
import { settingSelector } from "../store/Setting/setting.model";

function Listing() {
  const { t, i18n } = useTranslation();
  const data = useLocation().search;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /** States */
  const [query, setQuery] = useState(null);
  const [cateName, setCateName] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [categoryid, setcategoryid] = useState();
  const [subcategoryid, setsubcategoryid] = useState();
  const [locat, setLocat] = useState();
  const [val, setVal] = useState("");
  const [layout, setLayout] = useState({ list: true, grid: false });

  /** Selector Values */
  const { categories } = useSelector(categorySelector);
  const { busscat, getFilterBusinessData } = useSelector(bussnessCatSelector);
  const { location, country } = useSelector(settingSelector);


  const handleLayout = (ref, remove) => {
    setLayout({
      [ref]: true,
      [remove]: false,
    });
  };

  /** Functions */
  const handleQuery = (e) => {
    const { value } = e.target;
    setQuery(value);
  };
  const changCategoryName = () => {
    if (categoryid) {
      console.log(categories?.data, 3485)
      categories?.data?.map((categoryData) => {

        if (categoryData.id == categoryid) {
          console.log(categoryData.id == categoryid, 3457834)

          if (i18n.language == "ar")
            setCateName(categoryData.arabic_name)
          else
            setCateName(categoryData.name);
        }
      })
    } else {
      setCateName(query)
    }
  }

  /** useEffect Hooks */
  useEffect(() => {
    dispatch(categoryListRequest({}));
    dispatch(settingCountryRequest({}));
    dispatch(settingLocationRequest({}));
    dispatch(settingPlaceRequest({}));
  }, []);

  useEffect(() => {
    let queryString = data.substring(data.indexOf("?query=") + 7);
    console.log(queryString.split("&")[0], 48375)
    if (queryString) {
      setQuery(queryString.split("&")[0].replace("+", " "));
      setcategoryid(queryString.split("&")[1].replace("category=", ""));
      setsubcategoryid(queryString.split("&")[2].replace("subcategory=", ""));
      setLocat(queryString.split("&")[3].replace("location=", ""));
    }

  }, [data]);

  useEffect(() => {
    if (query != "" || categoryid != null || subcategoryid != null) {
      changCategoryName();
      if (locat)
        dispatch(
          searchRequest({
            page,
            limit,
            location: locat,
            category: categoryid,
            subcategory: subcategoryid,
            query,
          })
        );
    }
  }, [query, page, limit, categoryid, subcategoryid, locat]);

  useEffect(() => {
    if (locat == 0 || locat == 1) {
      setVal("riyadh");
    }
    if (locat == 2) {
      setVal("jeddah");
    }
    if (locat == 3) {
      setVal("madinah");
    }
  }, [locat]);

  useEffect(() => {
    console.log(i18n.language, 564757)
    changCategoryName()
  }, [i18n.language])
  console.log(query, 4869485)
  const handleChange = (fields) => {
    console.log(fields, 7587);
  };

  return (
    <div>
      <Header></Header>
      <section className="hero-area">
        <div className="breadcrumbs-wrapper">
          <Container>
            <Row>
              <Col className="z-index" lg={8}>
                <div className="page-title">
                  {/* <h1 className="title">Business List</h1> */}
                  <ul className="breadcrumbs-link">
                    <li className="link">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="active">Business List</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section className="listing-list-area pt-20 pb-90">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="listing-search-filter  ">
                <Row>
                  <Col md={5}>
                    <div className="fs-5 fw-bold">
                      <ul className="d-flex cursorStyle" >
                        <li>
                          {t(`${val}`)} {">"}
                        </li>
                        <li>
                          {cateName == null ? t("searchs") : cateName} {t("in")}  {t(`${val}`)} {">"}
                        </li>
                        <li>
                          {limit}
                          {"+ " + t("listing")}
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="filter-left d-flex justify-content-end">
                      <div className="sorting-dropdown">
                        <select
                          className="nice-select"
                          onChange={(e) => {
                            const { value } = e.target;
                            setcategoryid(value);
                          }}
                        >
                          <option data-dsplay="Default Sorting">
                            {t("default_sorting")}
                          </option>
                          {categories &&
                            categories?.data?.map((fields) => {
                              let catName = i18n.language == "ar" ? fields.arabic_name || fields.name :
                                    fields.name
                              return (
                                <option
                                  className="arrow"
                                  key={fields.id}
                                  value={fields.id}
                                >
                                  {catName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="filter-right">
                      <ul className="filter-nav">
                        <li>
                          <a
                            href={null}
                            className={layout?.grid ? "active" : ""}
                            onClick={() => handleLayout("grid", "list")}
                          >
                            <i className="ti-view-grid"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href={null}
                            className={layout?.list ? "active" : ""}
                            onClick={() => handleLayout("list", "grid")}
                          >
                            <i className="ti-view-list-alt"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>

              <div class="listing-list-wrapper mt-2">
                <Row>
                  <>
                    {getFilterBusinessData?.map((bussiness) => {
                      if (layout?.list)
                        return <ListView bussiness={bussiness} />;
                      else
                        return (
                          <>
                            <GridView bussiness={bussiness} />
                          </>
                        );
                    })}
                  </>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default Listing;
