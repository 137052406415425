import { call,put,takeEvery } from "redux-saga/effects";
import * as userDetails from './userdetail.action'
import { UserDetailTypes } from "./userdetail.model";
import Swal  from "sweetalert2";
import { postAllDetails, receiveAllDetails } from "./userdetail.api";
import { useTranslation } from "react-i18next";

const {t, i18n} = useTranslation
let arabicStatus = i18n?.language == "ar";

export function* sendAllDetail(action){
    try {
        const response=yield call(postAllDetails, action.payload)
        yield put(userDetails.postuserDetailsSuccess(response))
        Swal.fire({
            title: action.payload?.arabicStatus ? "بيانات المستخدم" : "User Data",
            text: action.payload?.arabicStatus ? "تم إرسال بيانات المستخدم بنجاح" :"User Deta Send Successfully",
            icon: "success",
          });
    } catch (e) {
        yield put(userDetails.postuserDetailsFailure(e))
        Swal.fire({
            title: action.payload?.arabicStatus ? "بيانات المستخدم" : "User Data",
            text: action.payload?.arabicStatus ? "فشل تحديث البيانات!" : "Updated Data Failed!",
            icon: "error",
          });
        
    }
}
function* getAllDetail(action){
    try{
    const responce=yield call(receiveAllDetails, action.payload)
    console.log(responce,"sagares")
    yield put(userDetails.getUserDetailSuccess(responce))
}catch(e){
    yield put(userDetails.getUserDetailFailure(e))
}
}

export function* UserDetailSaga(){
    yield takeEvery(UserDetailTypes.USERDETAIL_SEND_REQUEST, sendAllDetail)
    yield takeEvery(UserDetailTypes.GETUSERDETAIL_RECIEVE_REQUEST, getAllDetail)
}