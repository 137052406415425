import { call, put, takeEvery } from "redux-saga/effects";
import { LocationActionTypes,PlaceActionTypes,CountryActionTypes } from "./setting.model";
import * as SettingAction from "./setting.action";
import { getBYLocation, getByCountry,getByPlace } from "./setting.api";


export function* handleFetchLocation(action){
    try{
      const response= yield call (getBYLocation);
      yield put(SettingAction.settingLocationSuccess(response))
      console.log(response, "settingLocation");
  
    }catch (e){
      yield put (SettingAction.settingLocationFailure(e))
    }
  }
  
  export function* handleFetchByCountry(action){
    try{
      const response= yield call (getByCountry);
      yield put(SettingAction.settingCountrySuccess(response))
      console.log(response, "settingCountry");
  
    }catch (e){
      yield put (SettingAction.settingCountryFailure(e))
    }
  }
  
  export function* handleFetchByPlace(action){
    try{
      const response= yield call (getByPlace);
      yield put(SettingAction.settingPlaceSuccess(response))
      console.log(response, "settingPlace");
  
    }catch (e){
      yield put (SettingAction.settingPlaceFailure(e))
    }
  }

  export function* SettingSaga(){
    yield takeEvery(LocationActionTypes.LOCATION_LIST_REQUEST, handleFetchLocation);
    yield takeEvery(CountryActionTypes.COUNTRY_LIST_REQUEST, handleFetchByCountry);
    yield takeEvery(PlaceActionTypes.PLACE_LIST_REQUEST, handleFetchByPlace)
  }