import { action } from "typesafe-actions";
import { UserDetailTypes } from "./userdetail.model";

export const postuserDetailsRequest=(req)=>
    action(UserDetailTypes.USERDETAIL_SEND_REQUEST, req);
export const postuserDetailsSuccess =(res)=>
    action(UserDetailTypes.USERDETAIL_SEND_SUCCESS, res)
export const postuserDetailsFailure=(err)=>
    action(UserDetailTypes.USERDETAIL_SEND_FAILURE, err)

export const getUserDetailRequest=(req)=>
    action(UserDetailTypes.GETUSERDETAIL_RECIEVE_REQUEST, req)
export const getUserDetailSuccess=(res)=>
    action(UserDetailTypes.GETUSERDETAIL_RECIEVE_SUCCESS, res)
export const getUserDetailFailure=(err)=>
    action(UserDetailTypes.GETUSERDETAIL_RECIEVE_FAILURE, err)

export const resetStatesRequest=(req)=>
    action(UserDetailTypes.RESETSTATE_SEND_REQUEST,req)